import html2canvas from 'html2canvas'
// import canvg from 'canvg'
import jsPDF from 'jspdf'

// const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
//   console.log(b64Data)
//   const byteCharacters = window.atob(b64Data);
//   const byteArrays = [];

//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     const slice = byteCharacters.slice(offset, offset + sliceSize);

//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }

//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }
    
//   const blob = new Blob(byteArrays, {type: contentType});
//   return blob;
// }

// 截取畫面轉為base64
export async function getScreenCanvas (selector: any, hideSelector?: any) {
  try {
    let screenShotDom = null
    let hideDom = null
    let hideDomOriginStyle = ''

    if (hideSelector) {
      // -- 將要截圖的dom複製出來 --
      const targetDom = document.querySelector(selector)
      // let screenShotDom = targetDom.lastChild.cloneNode(true)
      screenShotDom = targetDom.cloneNode(true)
      // 因為dom的屬性有可能是相對的，複製clientWidth/clientHeight才不會因為放到別的位置造成寬高咆掉
      screenShotDom.setAttribute(
        'style',
        `${screenShotDom.getAttribute('style') || ''};width:${targetDom.clientWidth}px;height:${targetDom.clientHeight};`
      )
      document.querySelector('body')!.insertBefore(screenShotDom, document.body.firstChild)
      window.document.documentElement.scrollTop = 0 // 頁面捲動到最上面（這很重要，否則截圖可能會有bug）

      // -- 原本頁面隱藏 --
      if (hideSelector) {
        // 將原本的dom隱藏
        hideDom = document.querySelector(hideSelector)
        hideDomOriginStyle = hideDom.getAttribute('style')
        hideDom.setAttribute('style','display:none')
      }
    } else {
      screenShotDom = document.querySelector(selector)
    }
    if (screenShotDom == null) {
      console.error('null selector')
      return
    }

    // -- svg轉canvas --
    let nodesToRecover = []
    let nodesToRemove = []
    let renderedCanvas = null
    let svgElem = screenShotDom.querySelectorAll('svg')
    // if (svgElem && svgElem.length) {
    //   for (let i = 0; i < svgElem.length; i++) {
    //     let node = svgElem[i]
    //     let parentNode = node.parentNode
    //     let svg = node.outerHTML.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '') // 等同.trim()

    //     let canvas = document.createElement('canvas')
    //     // canvas.width = 650
    //     // canvas.height = 480
    //     if (node.style.position) {
    //       canvas.style.position += node.style.position
    //       canvas.style.left += node.style.left
    //       canvas.style.top += node.style.top
    //     }
    //     if (node.clientWidth) {
    //       canvas.width = node.clientWidth
    //     }
    //     if (node.clientHeight) {
    //       canvas.height = node.clientHeight
    //     }
    //     if (node.className) {
    //       canvas.className = node.className
    //     }
    //     // canvg(canvas, svg) // 舊版canvg寫法
    //     renderedCanvas = await canvg.from(canvas.getContext("2d"), svg)
    //     renderedCanvas.start()

    //     nodesToRecover.push({
    //       parent: parentNode,
    //       child: node
    //     })
    //     parentNode.removeChild(node)

    //     nodesToRemove.push({
    //       parent: parentNode,
    //       child: canvas
    //     })

    //     parentNode.appendChild(canvas)
    //   }

    //   renderedCanvas.stop()
    // }
    
    // -- 截圖 --
    const canvas = await html2canvas(screenShotDom, {
      background: '#fff'
    } as any)

    // -- svg還原 --
    // 說明：因為目前採用的方式是把要截圖的dom整個複製出來，截完圖拋棄，如果沒有做這個動作的話就必須將cans還原為svg
    // if (nodesToRemove.length && nodesToRecover.length) {
    //   nodesToRemove.forEach(d => {
    //     d.parent.removeChild(d.child)
    //   })
    //   nodesToRecover.forEach(d => {
    //     d.parent.appendChild(d.child)
    //   })
    // }

    // -- 還原畫面 --
    if (hideDom) {
      document.body.removeChild(screenShotDom)
      hideDom.setAttribute('style',hideDomOriginStyle)
    }

    return Promise.resolve(canvas)
    
  } catch (e) {
    // throw new Error(e)
    console.error(e)
    return Promise.reject(null)
  }
}

// 回傳base64圖片
export async function getScreenBase64 (selector: any, hideSelector?: any) {
  const canvas = await getScreenCanvas(selector, hideSelector)
  
  if (canvas) {
    return Promise.resolve(canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream'))
  } else {
    return Promise.reject(null)
  }
}

// 回傳Blob圖片
// export async function getScreenBlob (selector, fileName = 'content') {
//   const b64Data = await getScreenBase64(selector)

//   if (b64Data) {
//     const contentType = 'image/png';
//     const blob = b64toBlob(b64Data, contentType);
//     const blobUrl = URL.createObjectURL(blob);

//     const img = document.createElement('img');
//     img.src = blobUrl;

//     img.name = fileName

//     return Promise.resolve(img)
//   } else {
//     return Promise.reject(null)
//   }
// }

// 下載圖片
export async function downloadScreenImage (selector: any, fileName = 'content', hideSelector: any) {
  const canvas = await getScreenCanvas(selector, hideSelector)
  if (!canvas) {
    return
  }

  let img = document.createElement('a')
  img.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
  img.download = `${fileName}.jpg`
  img.click()

  return Promise.resolve(true)
}

// 下載pdf
// export async function downloadScreenPdf (selector: any, fileName = 'content', hideSelector: any) {
//   const canvas = await getScreenCanvas(selector, hideSelector)
//   if (!canvas) {
//     return
//   }

//   var contentWidth = canvas.width;
//   var contentHeight = canvas.height;
//   var padding = 20; // 邊距

//   //一页pdf显示html页面生成的canvas高度;
//   var pageHeight = contentWidth / 592.28 * 841.89;
//   //未生成pdf的html页面高度
//   var leftHeight = contentHeight;
//   //页面偏移
//   var position = 0;
//   //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
//   var imgWidth = 595.28 - (padding * 2);
//   var imgHeight = 592.28 / contentWidth * contentHeight;

//   var pageData = canvas.toDataURL('image/jpeg', 1.0);

//   var pdf = new jsPDF('', 'pt', 'a4');

//   //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
//   //当内容未超过pdf一页显示的范围，无需分页
//   if (leftHeight < pageHeight) {
//   pdf.addImage(pageData, 'JPEG', padding, 0, imgWidth, imgHeight );
//   } else {
//       while(leftHeight > 0) {
//           pdf.addImage(pageData, 'JPEG', padding, position, imgWidth, imgHeight)
//           leftHeight -= pageHeight;
//           position -= 841.89;
//           //避免添加空白页
//           if(leftHeight > 0) {
//             pdf.addPage();
//           }
//       }
//   }

//   await pdf.save(`${fileName}.pdf`, { returnPromise: true });
//   return Promise.resolve(true)
// }
